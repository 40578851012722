@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-image: url('assets/img/pexels-anni-roenkae-2156881.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;*/
  /*background-color: #E0F0F0;*/
  /*background-image: url('assets/img/bg_2.jpg');*/
  
  /*background: radial-gradient(#e6f5f6, #d7f0f0);*/
}

.bg1 {
  background-image: none;
  /*background-color: #E0F0F0;*/
  background-color: #FFFFFF;
}

@media only screen and (max-width: 1920px) {
  .bg2 {
    background-image: url('assets/img/bg_3.jpg');
  }
}

@media only screen and (min-width: 1921px) {
  .bg2 {
    background-image: url('assets/img/bg_2.jpg');
  }
}

h1 {
  text-transform: uppercase;
  color: #3C8087;
  font-weight: normal;
  margin-bottom: 64px;
  font-size: 32pt;
}

@media only screen and (max-width: 599px) {
  h1 {
    font-size: 20pt;
  }
}

#mainApp {
  /*margin-top: 128px;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 600px) {
  .minHeight {
    min-height: 720px;
    /*opacity: 0.85;*/
  }
  .minHeight-small {
    min-height: 445px;
  }

}

.hideSmall {
  display: none!important;
}

.hideSmallFlex {
  display: none!important;
}

@media only screen and (min-width: 1100px) {

  .hideLarge {
    display: none!important;
  }
  
  .hideSmall {
    display: unset!important;
  }

  .hideSmallFlex {
    display: flex!important;
  }

  .paddingLeft_MainPanel {
    padding-left: 48px!important;
  }
}

.flex-middle-center{ 
  Display: flex;
  Align-Items: center;
  Justify-Content: space-evenly;
  }

  .truncate-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .countdown {
    font-size: xx-large;
  }

  .MuiOutlinedInput-root {
    border-radius: 25px!important;
    /*background-color: #b9d7d8!important;*/
    background-color: #E0F0F0!important;
    /*font-weight: bold!important;*/
  }

  .MuiInputLabel-root.MuiInputLabel-formControl {
    font-weight: bold!important;
  }

  .MuiPaper-root {
    /*background: #6da2a8!important;*/
    
  }

  .MuiOutlinedInput-notchedOutline {
    border: none!important;
  }

  .MuiButton-outlined {
    border-radius: 40px !important;
    border-width: 1px !important;
    font-weight: bold!important;
    font-size: 16pt!important;


    background: linear-gradient(10deg, #34b7b2 30%, #289795 90%);
    border: 0;
    color: white!important;
    height: 64px;
    padding: 0 30px;
    /*box-shadow: 0 3px 5px 2px rgba(255, 160, 0, 0.3);*/
  }

  .MuiButton-outlined.Mui-disabled {
    background: linear-gradient(10deg, #c9cfcf 30%, #b5bebe 90%);
  }

  .appBarButton {
    font-size: large !important;
    color: #3b8087 !important;
    font-weight: bold !important;
    margin-right: 24px !important;
  }

  .bg2 .appBarButton {
    color: white !important;
  }

  a:visited { color: #3b8087; }
  a:link { color: #3b8087; }

  .benefit {
    background-color: #35B6B4;
    color: white;
    padding: 8px;
    margin-bottom: 4px;
    font-weight: bold;
  }

  .boxDark {
    background-color: #3C8087;
    color: white;
    padding: 16px;
    margin-bottom: 4px;
  }

  .firstPageMainInfo {
    padding: 32px;
  }

  .firstPageMainInfo .line1 {
    font-size: 24pt;
  }
  .firstPageMainInfo .line2 {
    font-size: 20pt;
    font-weight: bold;
  }
  .firstPageMainInfo .line3 {
    font-size: 38pt;
    font-weight: bold;
    padding: 32px 0px 32px 0;
  }
  .firstPageMainInfo .line4 {
    font-size: 24pt;
  }

  @media only screen and (min-width: 1440px) {
    .boxDark {
      position: absolute;
      width: 60%;
      top: 460px;
    }  
    .seppFirstPage {
      position: absolute;
      top: 130px;
      z-index: 1;
      right: 32px;
    }

    .firstPageMainInfoButton {
      position: absolute;
      width: 40%;
      top: 840px;
    }
  
  }


  
  @media only screen and (max-width: 1439px) {
    .seppFirstPage {
      width: 200px;
      margin: auto;
      padding-top: 16px;
    }  

    .thisIsSepp .arrowRight {
      display: none;
    }

    .firstPageMainInfo .line1 {
      font-size: 24pt;
    }
    .firstPageMainInfo .line2 {
      font-size: 16pt;
      font-weight: bold;
    }
    .firstPageMainInfo .line3 {
      font-size: 22pt;
      font-weight: bold;
      padding: 32px 0px 32px 0;
    }
    .firstPageMainInfo .line4 {
      font-size: 22pt;
    }
  }

  .thisIsSepp {
    font-family: 'Caveat', cursive;
    font-size: 26pt;
    text-align: center;
    color: #3b8087;
  }
  
  .guideContainer {
    display: flex;
    flex-wrap: wrap;
    background: white;
  }

  .guideItem {
    /*width: 170px;*/
    max-width: 170px;
    padding: 8px;
    text-align: center;

  }

  .faqHeader, .contactHeader {
    color: white!important;
    background: #3C8087;
    padding-left: 8px;
    font-size: 32pt!important;
  }

  .pictoTile {
    max-width: 10vw;
    text-align: center;
  }

  .pictoContainer {
    display: flex;
    flex-direction: row;

    padding-bottom: 16px;
    justify-content: space-between;
  }

    .pictoTile .header {
      font-weight: bold;
      margin-top: 24px;
    }

    .pictoTile .imageContainer {
      height: 140px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .pictoTile img {
      max-height: 10vh;
      max-width: 5vw;
      vertical-align: middle;
    }

    .mainLogoCenter {
      max-width: 20vw;
    }


    @media (max-width: 1000px) {
      .pictoContainer {
        flex-direction: column;
      }
  
      .pictoTile {
        max-width: 100%;
      }

      .pictoTile img {
        max-height: 33vh;
        max-width: 33vw;
      }

      .pictoTile .imageContainer {
        height: 25vh;
      }

      .pictoTile .header {
        font-size: x-large;
      }

      .pictoTile .description {
        font-size: large;
      }

      .seppCarouselNavButton:hover {
        background: gray !important;
      }

      .seppCarouselNavButton {
        background-color: transparent!important;
      }

      .seppCarousel {
        min-height: 300px!important;
      }

      .mainLogoCenter {
        max-width: 60vw;
      }
    }